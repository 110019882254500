import React, { useEffect, useState } from "react";
import FileFolderManager from "./FileFolderManager";
import conferenceServices from "../../services/conference.services";

const FileExplorer = ({ files, conference }) => {
  const [filesStructure, setFilesStructure] = useState([
    {
      name: "Session 1",
      image: "https://api.networkingtag.com/static/folder_2.png",
      parent: "",
      files: [],
      newSubFolderName: "",
    },
    {
      name: "Session 2",
      image: "https://api.networkingtag.com/static/folder_2.png",
      parent: "",
      files: [],
      newSubFolderName: "",
    },
  ]);

  const [selectedSubFolder, setSelectedSubFolder] = useState(null);
  const [loader, setLoader] = useState(false);

  const createSubFolder = (parentName, subFolderName) => {
    if (subFolderName.trim() === "") {
      return;
    }

    const _filesStructure = [...filesStructure];

    if (parentName == "Session 1") {
      const nameAfter = "Session 1";
      const nameBefore = "Session 2";

      // Find the indices of the specific session names
      const indexAfter = _filesStructure.findIndex(
        (obj) => obj.name === nameAfter
      );
      const indexBefore = _filesStructure.findIndex(
        (obj) => obj.name === nameBefore
      );

      if (indexAfter !== -1 && indexBefore !== -1 && indexAfter < indexBefore) {
        const newObject = {
          name: subFolderName,
          image: "https://api.networkingtag.com/static/folder_2.png",
          parent: parentName,
          files: [],
        };

        _filesStructure.splice(indexAfter + 1, 0, newObject);

        const newObjectIndex = _filesStructure.findIndex(
          (obj) => obj.name === newObject.name
        );
        const removedObject = _filesStructure.splice(newObjectIndex, 1)[0];
        _filesStructure.splice(indexBefore, 0, removedObject);
      }
    } else {
      _filesStructure.push({
        name: subFolderName,
        image: "https://api.networkingtag.com/static/folder_2.png",
        parent: parentName,
        files: [],
      });
    }

    setFilesStructure(_filesStructure);
  };

  const removeSubFolder = (index) => {
    const _filesStructure = [...filesStructure];

    _filesStructure.splice(index, 1);

    setFilesStructure(_filesStructure);
  };

  // Function to handle input change for each folder's subfolder name
  const handleInputChange = (parentName, value) => {
    setFilesStructure((prevStructure) => {
      return prevStructure.map((folder) => {
        if (folder.name === parentName) {
          folder.newSubFolderName = value; // Set input value for the specific folder
        }
        return folder;
      });
    });
  };

  // Function to handle subfolder selection
  const handleSubFolderClick = (index) => {
    setSelectedSubFolder(index);
  };

  // Render the folder structure
  const renderFolders = (folders) => {
    return folders.map((folder, index) => (
      <>
        {folder.parent == "" ? (
          <div
            key={`${index}_${folder.name}`}
            style={{
              paddingLeft: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              {folder.name}
            </div>

            <div style={{ marginTop: 10 }}>
              <input
                type="text"
                placeholder="Enter subfolder name"
                value={folder.newSubFolderName} // Use folder-specific input value
                onChange={(e) => handleInputChange(folder.name, e.target.value)} // Handle change for specific folder
                className="input-subfolder" // Apply the CSS class
              />

              <button
                onClick={() =>
                  createSubFolder(folder.name, folder.newSubFolderName)
                }
                className="fex_button_1"
                style={{ marginTop: "10px", marginBottom: "10px" }}
              >
                Add Subfolder
              </button>
            </div>
          </div>
        ) : (
          <div style={{}}>
            <div
              key={`${index}_${folder.name}`}
              style={{
                marginLeft: 20,
                cursor: "pointer",
                marginBottom: "12px",
                marginTop: "12px",
                padding: "20px 5px",
                borderRadius: "20px",
              }}
              onClick={() => handleSubFolderClick(index)}
            >
              <span className="fex_folder_name">{folder.name}</span>
              <button
                onClick={() => removeSubFolder(index)}
                className="fex_button_remove" // Apply the "fex_" class
              >
                Remove
              </button>
            </div>
          </div>
        )}
      </>
    ));
  };

  // useEffect(() => {
  //   console.log("filesStructure", filesStructure);
  // }, [filesStructure]);

  const handleAddFile = () => {
    setLoader(true);
    conferenceServices
      .updateConference(conference._id, {
        ...conference,
        resources_structure: filesStructure,
      })
      .then(() => {
        setLoader(false);
        window.location.reload();
      })
      .catch((err) => alert(err.message));
  };

  useEffect(() => {
    if (conference && conference.resources_structure) {
      setFilesStructure(conference.resources_structure);
    }
  }, [conference]);

  return (
    <div style={{ display: "flex", borderRadius: "20px" }}>
      {/* Left Side: Folder Structure */}
      <div
        style={{
          width: "300px",
          backgroundColor: "#f4f4f4",
          padding: "10px",
          borderTopLeftRadius: "20px",
          height: "100vh",
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            margin: "10px",
            fontWeight: "bold",
          }}
        >
          Resource Structure
        </div>
        {renderFolders(filesStructure)}
        <div
          style={{
            height: "200px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loader ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <div class="loader_KfnSdkCmel"></div>
            </div>
          ) : (
            <button onClick={handleAddFile} className="submit_save_button">
              Save
            </button>
          )}
        </div>
      </div>

      {/* Right Side: Content for selected folder */}
      <div
        style={{
          flex: 1,
          padding: "10px",
          borderLeft: "1px solid #ddd",
          borderTopRightRadius: "20px",
        }}
      >
        <div
          style={{
            borderRadius: "10px",
            padding: "10px",
            margin: "10px",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          Details of Selected Subfolder{" "}
        </div>
        {selectedSubFolder ? (
          <div>
            {filesStructure[selectedSubFolder].name != "Session 1" &&
            filesStructure[selectedSubFolder].name != "Session 2" ? (
              <>
                <div
                  style={{
                    borderRadius: "10px",
                    padding: "10px",
                    margin: "10px",
                    fontWeight: "bold",
                  }}
                >
                  Selected Subfolder: {filesStructure[selectedSubFolder].name}
                </div>
                <FileFolderManager
                  files={files}
                  folderIndex={selectedSubFolder}
                  setFilesStructure={setFilesStructure}
                  folders={filesStructure}
                />
              </>
            ) : null}
          </div>
        ) : (
          <div
            style={{
              borderRadius: "10px",
              padding: "10px",
              margin: "10px",
              fontWeight: "bold",
            }}
          >
            Select a subfolder to view details.
          </div>
        )}
      </div>
    </div>
  );
};

export default FileExplorer;
