import React, { useState, useEffect, useRef } from "react";
import { useToast } from "./use-toast";
import "./ImageGridWidget.scss";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground",
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm",
      className
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

const ImageGridWidget = ({ conferences }) => {
  const [selectedConference, setSelectedConference] = useState(
    conferences[0]?._id || ""
  );
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);
  const [uploadingKey, setUploadingKey] = useState(null);
  const [editingColors, setEditingColors] = useState(null);
  const { toast } = useToast();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://api.networkingtag.com/static/v3/jsons/${selectedConference}.json`
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch data: ${response.status}`);
        }

        const jsonData = await response.json();
        setData(jsonData);
        setError(null);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load conference data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    if (selectedConference) {
      fetchData();
    }
  }, [selectedConference]);

  const handleTabChange = (value) => {
    setSelectedConference(value);
  };

  const handleImageClick = (key) => {
    setUploadingKey(key);
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (e) => {
    const file = e.target.files?.[0];
    if (!file || !uploadingKey) return;

    const formData = new FormData();
    formData.append("file", file);
    formData.append("jsonFileName", `${selectedConference}.json`);
    formData.append("key", uploadingKey);
    formData.append("width", 40);
    formData.append("height", 40);

    try {
      setLoading(true);
      const response = await fetch("https://api.networkingtag.com/api/record", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to upload image: ${response.status}`);
      }

      toast({
        title: "Image Updated",
        description: "The image has been successfully updated.",
      });

      // Refresh data after successful upload
      const refreshResponse = await fetch(
        `https://api.networkingtag.com/static/v3/jsons/${selectedConference}.json`
      );
      const refreshedData = await refreshResponse.json();
      setData(refreshedData);
    } catch (err) {
      console.error("Error uploading image:", err);
      toast({
        title: "Upload Failed",
        description: "Failed to upload the image. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      setUploadingKey(null);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  const handleColorEdit = () => {
    if (data?.colors) {
      setEditingColors({ ...data.colors });
    }
  };

  const handleColorChange = (colorKey, value) => {
    if (editingColors) {
      setEditingColors({
        ...editingColors,
        [colorKey]: value,
      });
    }
  };

  const handleColorSave = async () => {
    if (!editingColors) return;

    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("jsonFileName", `${selectedConference}.json`);
      formData.append("key", "colors");
      formData.append("primaryColor", editingColors.primaryColor);
      formData.append("secondaryColor", editingColors.secondaryColor);
      formData.append("thirdColor", editingColors.thirdColor);

      const response = await fetch("https://api.networkingtag.com/api/record", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Failed to update colors: ${response.status}`);
      }

      toast({
        title: "Colors Updated",
        description: "The color palette has been successfully updated.",
      });

      // Refresh data after successful update
      const refreshResponse = await fetch(
        `https://api.networkingtag.com/static/v3/jsons/${selectedConference}.json`
      );
      const refreshedData = await refreshResponse.json();
      setData(refreshedData);
    } catch (err) {
      console.error("Error updating colors:", err);
      toast({
        title: "Update Failed",
        description: "Failed to update colors. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
      setEditingColors(null);
    }
  };

  const handleColorCancel = () => {
    setEditingColors(null);
  };

  // Filter out the colors object to get only image objects
  const imageEntries = data
    ? Object.entries(data).filter(
        ([key, value]) => key !== "colors" && "url" in value
      )
    : [];

  if (loading && !data) {
    return <div className="loading-state">Loading conference data...</div>;
  }

  if (error && !data) {
    return <div className="error-state">{error}</div>;
  }

  return (
    <div className="image-grid-widget">
      {/* <h1 className="image-grid-widget__title">Conference Assets</h1> */}

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileChange}
      />

      <Tabs
        defaultValue={selectedConference}
        onValueChange={handleTabChange}
        className="conference-tabs"
      >
        <TabsList className="conference-tabs-list">
          {conferences.map((conference) => (
            <TabsTrigger key={conference._id} value={conference._id}>
              {conference.name}
            </TabsTrigger>
          ))}
        </TabsList>

        {conferences.map((conference) => (
          <TabsContent
            key={conference._id}
            value={conference._id}
            className="conference-tab-content"
          >
            {selectedConference === conference._id && data && (
              <>
                <div className="conference-info">
                  <h2>{conference.name}</h2>
                  <p>
                    <strong>Host:</strong> {conference.hostname}
                  </p>
                  <p>
                    <strong>Location:</strong> {conference.address}
                  </p>
                </div>

                <div className="image-grid-widget__color-palette">
                  <div className="color-header">
                    <h2 className="image-grid-widget__subtitle">
                      Color Palette
                    </h2>
                    {!editingColors ? (
                      <button
                        className="edit-button"
                        onClick={handleColorEdit}
                        disabled={loading}
                      >
                        Edit Colors
                      </button>
                    ) : (
                      <div className="color-actions">
                        <button
                          className="save-button"
                          onClick={handleColorSave}
                          disabled={loading}
                        >
                          Save
                        </button>
                        <button
                          className="cancel-button"
                          onClick={handleColorCancel}
                          disabled={loading}
                        >
                          Cancel
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="color-palette">
                    {data.colors &&
                      Object.entries(data.colors).map(
                        ([colorName, colorValue]) =>
                          !["secondaryColor", "thirdColor"].includes(
                            colorName
                          ) ? (
                            <div className="color-item" key={colorName}>
                              <div
                                className="color-swatch"
                                style={{
                                  backgroundColor:
                                    editingColors && colorName in editingColors
                                      ? editingColors[colorName]
                                      : colorValue,
                                }}
                              ></div>
                              <div className="color-info">
                                <p className="color-name">{colorName}</p>
                                {editingColors ? (
                                  <input
                                    type="color"
                                    value={
                                      editingColors[colorName] || colorValue
                                    }
                                    onChange={(e) =>
                                      handleColorChange(
                                        colorName,
                                        e.target.value
                                      )
                                    }
                                    className="color-picker"
                                  />
                                ) : (
                                  <p className="color-value">{colorValue}</p>
                                )}
                              </div>
                            </div>
                          ) : null
                      )}
                  </div>
                </div>

                <div className="image-grid-widget__images">
                  <h2 className="image-grid-widget__subtitle">Images</h2>
                  {loading && (
                    <div className="loading-overlay">
                      <span>Processing...</span>
                    </div>
                  )}
                  <div className="image-grid">
                    {imageEntries.map(([key, imageData]) =>
                      ![
                        "profileCropIcon",
                        "linkedinIcon",
                        "horizontalLogoImage",
                        "logoImage",
                      ].includes(key) ? (
                        <div
                          className={`image-item ${
                            uploadingKey === key ? "uploading" : ""
                          }`}
                          key={key}
                          onClick={() => handleImageClick(key)}
                        >
                          <div className="image-container">
                            <img
                              src={imageData.url}
                              alt={key}
                              className="image"
                            />
                            {/* <div className="image-overlay">
                              <span>Click to change image</span>
                            </div> */}
                          </div>
                          <div className="image-info">
                            <p className="image-name">{key}</p>
                            {/* <p className="image-url">{imageData.url}</p> */}
                          </div>
                        </div>
                      ) : null
                    )}
                  </div>
                </div>
              </>
            )}
          </TabsContent>
        ))}
      </Tabs>
    </div>
  );
};

export default ImageGridWidget;
