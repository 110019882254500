import React, { useEffect, useState } from "react";

const FileFolderManager = ({
  files,
  folderIndex,
  setFilesStructure,
  folders,
}) => {
  const [selectedFile, setSelectedFile] = useState("");
  const [updatedFolder, setUpdatedFolder] = useState(folders[folderIndex]);

  // const handleAddFile = () => {
  //   if (selectedFile) {
  //     setUpdatedFolder({
  //       ...updatedFolder,
  //       files: [...updatedFolder.files, files[selectedFile]],
  //     });
  //   }
  // };

  const handleAddFile = () => {
    if (selectedFile) {
      // Get the file to be added
      const fileToAdd = files[selectedFile];

      // Check if the file already exists in the updatedFolder's files array
      const isDuplicate = updatedFolder.files.some(
        (file) => file.name === fileToAdd.name
      );

      if (!isDuplicate) {
        // If not a duplicate, add the file
        setUpdatedFolder({
          ...updatedFolder,
          files: [...updatedFolder.files, fileToAdd],
        });
      } else {
        // Optionally, you could alert the user or log a message if a duplicate is found
        // console.log("This file is already in the folder.");
        alert("This file is already in the folder.");
      }
    }
  };

  const handleRemoveFile = (index) => {
    let files = JSON.parse(JSON.stringify(updatedFolder.files));
    files.splice(index, 1);
    setUpdatedFolder({
      ...updatedFolder,
      files,
    });
  };

  useEffect(() => {
    const _folders = JSON.parse(JSON.stringify(folders));
    _folders[folderIndex] = JSON.parse(JSON.stringify(updatedFolder));
    setFilesStructure(_folders);
  }, [updatedFolder]);

  useEffect(() => {
    setUpdatedFolder(folders[folderIndex]);
  }, [folderIndex]);

  return (
    <div className="FileFolderManager_container">
      <h2 className="FileFolderManager_header">Manage Files in Folder</h2>

      <div>
        <label className="FileFolderManager_label">Select File:</label>
        <select
          value={selectedFile}
          onChange={(e) => setSelectedFile(e.target.value)}
          className="FileFolderManager_select"
        >
          <option value="">-- Select File --</option>
          {files.map((file, index) => (
            <option key={file.name} value={index}>
              {file.name}
            </option>
          ))}
        </select>
      </div>

      <button onClick={handleAddFile} className="FileFolderManager_button">
        Add File to Folder
      </button>

      <div className="FileFolderManager_files_section">
        <h3>Files in "{updatedFolder.name}"</h3>
        {updatedFolder.files.length === 0 ? (
          <p>No files in this folder</p>
        ) : (
          <ul>
            {updatedFolder.files.map((file, index) => (
              <li key={file.name} className="FileFolderManager_file_item">
                {file.name}{" "}
                <button
                  onClick={() => handleRemoveFile(index)}
                  className="FileFolderManager_remove_button"
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default FileFolderManager;
