import styled from "styled-components";

export const ConferenceWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

export const ConferenceCard = styled.div`
  width: 95%;
  // max-width: 320px;
  max-width: 460px;
  padding: 2.5%;
  border-radius: 30px;
  margin-right: 10px;
  background-color: #f8f8f8;
  .c-card {
    position: relative;
    background: transparent;
    z-index: 2;
  }
  .c-card > .v-card {
    top: 0;
    left: 20%;
    width: 90%;
    height: 90%;
    position: absolute;
    background: url(${(props) => props.imgUrl});
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: right;
    background-color: transparent;
    z-index: 1;
  }
  .c-card > h3 {
    width: 80%;
    white-space: wrap;
    background: transparent;
    margin: 0;
    font-size: 25px;
    font-weight: 600;
    color: #ffc727;
  }

  .c-card > .content {
    list-style: none;
    width: 50%;
    padding: 0;
    margin: 13px 0 21px 0;
    background: transparent;
  }

  .c-card > .content > li {
    display: flex;
    width: 100%;
    margin: 10px 0;
    white-space: wrap;
    background: transparent;
    color: #000000;
  }

  .c-card > .content > li > img {
    width: 16px;
    height: 16px;
    margin: 0 10px auto 0;
    background: transparent;
  }
  .c-card > .content > li > span {
    font-size: 14px;
    font-family: Gilroy;
    font-weight: 400;
    margin: auto 0;
    background: transparent;
  }

  .c-card > .circle {
    width: 20px;
    height: 20px;
    padding: 5px;
    background: rgb(255, 199, 39);
    border-radius: 100%;
    cursor: pointer;
  }
`;

export const Details = styled.div`
  width: 100%;
  padding: 0;
  margin-bottom: 45px;
  display: grid;
  grid-template-columns: 50% 50%;
  li {
    display: flex;
    width: 100%;
    margin: 30px 0 0 0;
    white-space: wrap;
    background: transparent;
    color: #000000;
  }

  li > img {
    width: 24px;
    height: 24px;
    margin: 0 10px auto 0;
    background: transparent;
  }
  li > span {
    display: flex;
    font-family: GilroyMed;
    font-size: 1.1rem;
    font-weight: 400;
    margin: 0 0 auto 0;
    background: transparent;
  }

  li > span > .status {
    width: fit-content;
    padding: 6px 13px;
    font-family: GilroyMed;
    font-size: 1.1rem;
    border-radius: 4px;
    color: #414141;
  }
  li > span > .not-started {
    background: #a9c6ff;
  }
  li > span > .open {
    background: #c0ffa9;
  }
  li > span > .closed {
    background: #ff9393;
  }
  .dot {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin: auto 20px;
    background: #d0d6db;
  }
  li > span > label {
    margin: auto 0;
    font-family: GilroyMed;
  }
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: fit-content;
  grid-template-columns: 32.5% 32.5% 32.5%;
  grid-gap: 0.83%;
`;

export const SponsorshipWrapper = styled.div`
  width: 90%;
  padding: 5%;
  margin-bottom: 5px;
  border: 1px solid rgb(241, 241, 241);
  background: #fffffa;
  border-radius: 10px;
  display: flex;
  img {
    width: 80px;
    margin-right: 10px;
  }
  p {
    white-space: wrap;
    margin: auto 0;
  }
`;

export const AgendaWrapper = styled.div`
  width: 100%;
  padding: 0%;
  margin-bottom: 5px;
  background: #fffffa;
  .day {
    margin: 0;
    font-family: GilroyMed;
  }
  .types {
    width: 100%;
  }
`;

export const TypeWrapper = styled.div`
  width: 90%;
  padding: 5%;
  margin-bottom: 5px;
  border: 1px solid rgb(241, 241, 241);
  border-radius: 10px;
  cursor: pointer;
  .type-head {
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => props.thmb || 0};
  }
  .type-head > h4 {
    margin: auto 0;
    font-size: 0.9rem;
  }
  .type-head > img {
    width: 20px;
    height: 20px;
    margin: auto 0;
    transform: rotate(-180deg);
    transition: 0.4s ease;
  }
  .type-head > .img-active {
    transform: rotate(90deg);
  }
  .topics {
    list-style: none;
    transition: 0.4s ease;
    padding: 0;
    margin: 0;
    height: ${(props) => props["topic-height"]}px;
    overflow: hidden;
  }
  .topics > li {
    padding: 8px 0;
    font-family: Gilroy;
    .type {
      width: 100%;
      white-space: wrap;
      font-family: Poppins;
      font-size: 0.9rem;
    }
    p {
      display: flex;
      justify-content: space-between;
      font-size: 0.8rem;
      color: rgb(160, 160, 160);
      margin: 0;
    }
    p > .time {
      width: fit-content;
      white-space: nowrap;
    }
  }
`;
