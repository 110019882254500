import FileSaver from "file-saver";
import { ConferenceAPIs } from "../apis";
import { readExcelFile } from "../utils/excelReader";
import { get, post, patch, remove } from "../utils/request";

async function getConferences() {
  const response = await get({
    url: ConferenceAPIs.fetchConferences,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function getConference(conferenceId) {
  const response = await get({
    url: ConferenceAPIs.fetchConference(conferenceId),
  });
  if (response.status !== "success") throw response;
  return response;
}

const mergeDateTime = (date, time) => {
  if (!time || !date) return;
  return new Date();
};
async function updateConference(conferenceId, conference) {
  const response = await patch({
    url: ConferenceAPIs.updateConference(conferenceId),
    data: {
      name: conference.name,
      hostname: conference.hostname,
      liveOn: conference.liveOn,
      endOn: conference.endOn,
      address: conference.address,
      website: conference.website,
      company_chat: conference.company_chat,
      team_module: conference.team_module,
      resources_structure: conference.resources_structure,
    },
  });
  if (response.status !== "success") throw response;
  return response;
}

async function createConference(conference) {
  const response = await post({
    url: ConferenceAPIs.createConference(),
    data: {
      ...conference,
      startTime: new Date(conference.liveOn.date),
      endTime: new Date(conference.endOn.date),
    },
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateConferenceImage(files, conferenceId) {
  const formdata = new FormData();
  files.forEach((file) => {
    formdata.set(file.field, file.content);
  });

  const response = await patch({
    url: ConferenceAPIs.updateImageConference(conferenceId),
    data: formdata,
    contenttype: "formdata",
  });
  if (response.status !== "success") throw response;
  return response;
}

async function importSponsorships(file, conferenceId, resources) {
  if (resources) {
    //read data from excel and map content
    const jsonData = await readExcelFile(file, {
      Name: "name",
      Logo: "logo",
      "PDF Url": "sponsorship_type",
      Tier: "tier",
      "Company Link": "company_link",
      Description: "description",
    });

    jsonData.forEach((asd) => {
      asd.tier = "-";
      asd.company_link = "-";
      asd.description = "-";
    });

    const response = await patch({
      url: ConferenceAPIs.importSponsorships(conferenceId),
      data: {
        sponsorships: jsonData,
        resources,
      },
    });
    if (response.status !== "success") throw response;
    return response;
  } else {
    //read data from excel and map content
    const jsonData = await readExcelFile(file, {
      Name: "name",
      Logo: "logo",
      "Sponsorship Type": "sponsorship_type",
      Tier: "tier",
      "Company Link": "company_link",
      Description: "description",
    });
    const response = await patch({
      url: ConferenceAPIs.importSponsorships(conferenceId),
      data: {
        sponsorships: jsonData,
        resources,
      },
    });
    if (response.status !== "success") throw response;
    return response;
  }
}

async function updateSponsor(conferenceId, sponsorId, resources, data) {
  //read data from excel and map content
  const response = await patch({
    url: ConferenceAPIs.updateSponsorship(conferenceId, sponsorId, resources),
    data: data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function removeSponsor(conferenceId, sponsorId, resources) {
  //read data from excel and map content
  const response = await remove({
    url: ConferenceAPIs.removeSponsorship(conferenceId, sponsorId, resources),
  });
  if (response.status !== "success") throw response;
  return response;
}

async function importAgendas(file, conferenceId) {
  //read data from excel and map content
  const jsonData = await readExcelFile(
    file,
    {
      Day: "day",
      Date: "date",
      Type: "type",
      Topic: "topic",
      Link: "link",
      Description: "description",
      Time: "time",
      Location: "location",
      "Speaker(s)": "speaker",
    },
    undefined,
    (row) => {
      if (!row.day || !row.date || !row.time) return false;
      else return true;
    }
  );

  const response = await patch({
    url: ConferenceAPIs.importAgendas(conferenceId),
    data: {
      agendas: jsonData,
    },
  });
  if (response.status !== "success") throw response;
  if (response.data.errors) {
    const error = response.data.errorLogs
      .map((err) => `Error on row ${err.row}, ${err.error}`)
      .join("\n");
    const blob = new Blob([error], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, `ErrorLogs_${Date.now()}.txt`);
  }
  return {
    data: {
      agendas: response.data.agendas,
    },
  };
}

async function fetchAgendas(conferenceId) {
  const response = await get({
    url: ConferenceAPIs.fetchAgendas(conferenceId),
  });
  if (response.status !== "success") throw response;
  return { data: { agendas: response.data.agendas } };
}

async function addAgenda(conferenceId, data) {
  if (
    !data.agendaDate ||
    !data.startTime ||
    !data.endTime ||
    !data.type ||
    !data.topic
  )
    throw { message: "Please fill all fields" };
  const response = await post({
    url: ConferenceAPIs.addAgenda(conferenceId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function updateAgenda(conferenceId, agendaId, data) {
  const response = await patch({
    url: ConferenceAPIs.updateAgenda(conferenceId, agendaId),
    data,
  });
  if (response.status !== "success") throw response;
  return response;
}

async function removeAgenda(conferenceId, agendaId) {
  const response = await remove({
    url: ConferenceAPIs.removeAgenda(conferenceId, agendaId),
  });
  if (response.status !== "success") throw response;
  return response;
}

const services = {
  getConferences,
  getConference,
  updateConference,
  updateConferenceImage,
  importSponsorships,
  updateSponsor,
  removeSponsor,
  importAgendas,
  addAgenda,
  updateAgenda,
  removeAgenda,
  fetchAgendas,
  createConference,
};

export default services;
